import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const NotFound = () => {

    return (
      <Layout>
      <Seo title="Home" />
      <div>
      <h1>404 Stránka nenájdená</h1>
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
    </Layout>
    )

};

export default NotFound